<template>
  <div class="content">
    <!--[main]-->
    <div class="main">
      <PageHeader :pageName="pageName" :pageNavs="pageNavs" />
      <section class="section">
        <div class="row">
          <div class="col-8">
            <Panel type="board">
              <template v-slot:headline>検索条件</template>
              <template v-slot:body>
                <div class="search">
                  <div class="search-row">
                    <div class="row">
                      <div class="col">
                        <FormRow>
                          <template v-slot:label>店舗名</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-icon form-icon-search">
                                <input
                                  v-trim
                                  v-maxlength
                                  maxlength='45'
                                  class="form-control form-icon-input"
                                  type="text"
                                  name="name"
                                  v-model="searchForm.name" />
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                    </div>
                  </div>
                  <div class="search-row">
                    <div class="row">
                      <div class="col">
                        <FormRow>
                          <template v-slot:label>企業名</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-icon form-icon-search">
                                <input
                                  v-trim
                                  v-maxlength
                                  maxlength='45'
                                  class="form-control form-icon-input"
                                  type="text"
                                  name="company"
                                  v-model="searchForm.company"
                                />
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                    </div>
                  </div>
                  <div class="search-row">
                    <div class="row">
                      <div class="col">
                        <FormRow>
                          <template v-slot:label>ステータス</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-select">
                                <select
                                  class="form-control form-select-input"
                                  name="status"
                                  v-model="searchForm.status"
                                >
                                  <option
                                    v-for="item in shopStatusList"
                                    :key="item.id"
                                    :value="item.value"
                                  >{{ item.label }}</option>
                                </select>
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template v-slot:contentFooter>
                <ul class="listGrid justify-content-end">
                  <li class="listGrid-item">
                    <button @click="resetSearch" class="btn btn-white" type="button">リセット</button>
                  </li>
                  <li class="listGrid-item">
                    <button @click="search" class="btn btn-main" type="button">検索</button>
                  </li>
                </ul>
              </template>
            </Panel>
          </div>
          <div class="col-4">
            <Panel type="board" :padding="false" :center="true">
              <template v-slot:body>
                <NumDisplay position="center" color="dark">
                  <template v-slot:title>可決累計数</template>
                  <template v-slot:num>{{ approvedShopTotal | comma }}</template>
                  <template v-slot:sup>件</template>
                  <template v-slot:sub>
                    <span class="numDisplay-sub-sup">申請累計数</span>{{ shopTotal | comma }}件
                  </template>
                </NumDisplay>
              </template>
            </Panel>
          </div>
        </div>
      </section>
      <section class="section">
        <div class="row">
          <div class="col">
            <Panel type="board" :padding="false">
              <template v-slot:body>
                <div class="scrollX">
                  <TableWithCheckbox
                    ref="table"
                    :storeModule="storeModule"
                    :labels="labels"
                    :data-table="dataTable"
                    :count="shopCount"
                    @select-items="handleCheckedData"
                  />
                </div>
              </template>
              <template v-slot:footer>
                <PaginationLog
                  :listLength="shopCount"
                  :modulePath="modulePath"
                  :searchConditions="searchConditions"
                  ref="pagination"
                />
              </template>
            </Panel>
          </div>
        </div>
      </section>
    </div>
    <!--[/main]-->
    <!--[footer]-->
    <PageFooter>
      <template v-slot:footer>
        <ul class="listGrid">
          <li class="listGrid-item">
            <button class="btn btn-green" type="button" @click="judgeShops">まとめて審査</button>
          </li>
          <li class="listGrid-item">
            <ActionButton :disabled="!shopList.length || isDisable" class="btn btn-black" :handle-submit="hanldeClickDownload" button-text="CSVダウンロード" />
          </li>
          <li class="listGrid-item" v-if="!isClientChild">
            <button class="btn btn-black" type="button" @click="uploadCsv" :disabled="isImportDisabled">CSVアップロード</button>
            <input
              type="file"
              style="display: none"
              ref="csvInput"
              accept=".csv"
              @change="onCsvFilePicked"
            />
          </li>
          <li class="listGrid-item pos-end">
            <router-link class="btn btn-main" :to="{ name: 'EventShopRegister' }">新規登録</router-link>
          </li>
        </ul>
      </template>
    </PageFooter>
    <!--[/footer]-->
    <Modal @close="closeModal" v-if="modal">
      <template v-slot:headline>審査</template>
      <template v-slot:body>
        <FormRow>
          <template v-slot:content>
            <div class="form-content-row">
              <ul class="selectBtn">
                <li v-for="item in Object.values(judgeTypes)" :key="item.value" class="selectBtn-item">
                  <label class="selectBtn-btn" :class="item.class">
                    <input
                      class="selectBtn-input"
                      type="radio"
                      :value="item.value"
                      name="judgeType"
                      v-model="statusForm.status"
                    />
                    <span class="selectBtn-label">{{ item.label }}</span>
                  </label>
                </li>
              </ul>
            </div>
          </template>
        </FormRow>
        <transition name="fade">
          <FormRow v-if="statusForm.status === judgeTypes.hold.value">
            <template v-slot:label>保留理由</template>
            <template v-slot:labelNote>（100文字以内）</template>
            <template v-slot:content>
              <div class="form-content-row">
                <textarea
                  v-trim
                  v-maxlength
                  maxlength="100"
                  class="form-control form-textarea"
                  name="note"
                  v-model="statusForm.note"
                ></textarea>
              </div>
            </template>
          </FormRow>
        </transition>
      </template>
      <template v-slot:footer>
        <ul class="listGrid justify-content-end">
          <li class="listGrid-item">
            <button class="btn btn-white" type="button" @click="handleClear">キャンセル</button>
          </li>
          <li class="listGrid-item">
            <ActionButton class="btn btn-main" :handleSubmit="handleExamination" buttonText="登録する" />
          </li>
        </ul>
      </template>
    </Modal>
    <ModalConfirmDownload
      v-if="downloadCSVModal"
      @cancelDownloadCsv="cancelDownloadCsv"
      @acceptDownloadCsv="acceptDownloadCsv"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required, maxLength } from 'vuelidate/lib/validators';
import { getEncodedRefreshToken } from '@/helpers/http';
import * as Encoding from 'encoding-japanese';
//mixins
import nav from '@/mixins/nav/shop';
import status from '@/mixins/plugin/status';
import modal from '@/mixins/plugin/modal';
import search from '@/mixins/plugin/search';
import checkall from '@/mixins/plugin/checkall';
import error from '@/mixins/plugin/error';
import download from '@/mixins/plugin/download';
import reloadOnPage from '@/mixins/module/reloadOnPage';
//component
import FormRow from '@/components/FormRow.vue';
import NumDisplay from '@/components/NumDisplay.vue';
import Modal from '@/components/Modal.vue';
import TableWithCheckbox from '@/components/TableWithCheckbox.vue';
import PaginationLog from '@/components/PaginationLog.vue';
import ActionButton from '@/components/ActionButton.vue';
import ModalConfirmDownload from '@/components/ModalConfirmDownload.vue';
//constant
import { StatusConstants } from '@/constants/status';
import { FileConstants } from '@/constants/file';
//helpers
import { formatDateAndTime, addThreeDotEndLine } from '@/helpers/formatData';
import { checkFileSize, checkCsvFile } from '@/helpers/file';

const popStateListener =() => {
  history.go(1);
}

export default {
  name: 'EventShop',
  data: function() {
    return {
      pageName: '店舗管理',
      judgeType: '',
      labels: [
        { isCheckbox: true },
        { key: 'name', name: '店舗名' },
        { key: 'company', name: '企業名' },
        { key: 'genreName', name: 'ジャンル' },
        { key: 'registDate', name: '申請日時', sortKey: 'createDate' },
        { key: 'judgeDate', name: '審査処理日時', sortKey: 'judgeDate' },
        { key: 'status', name: 'ステータス' },
        { isButtonColumn: true }
      ],
      modulePath: 'shop/getShopList',
      searchConditions: {},
      statusForm: {
        note: '',
        status: '',
        shops: [],
        allFlag: false,
      },
      judgeTypes: StatusConstants.judge,
      storeModule: 'shop',
      searchFields: ['name', 'company', 'status'],
      csvType: 'text/csv',
      isImportDisabled: false,
      statusTimer: null,
      storedField: 'idRequestExportShop',
    };
  },
  mixins: [nav, checkall, status, modal, search, error, download, reloadOnPage],
  components: {
    FormRow,
    NumDisplay,
    Modal,
    TableWithCheckbox,
    PaginationLog,
    ActionButton,
    ModalConfirmDownload,
  },
  validations: {
    statusForm: {
      status: { required },
      note: { maxLength: maxLength(100) },
    }
  },
  mounted () {
    if (this.$route.query?.back) {
      history.pushState(null, document.title, location.href);
      window.addEventListener('popstate', popStateListener);
    }
    this.getTotalByStatus();
    const importingId = (JSON.parse(localStorage.getItem('IMPORTING_SUBDOMAIN')) || {})[this.importPath];
    if (importingId) {
      this.isImportDisabled = true;
      this.checkImportStatus(importingId);
    }
  },
  computed: {
    ...mapGetters({
      shopList: 'shop/shopList',
      shopTotal: 'shop/shopTotal',
      shopCount: 'shop/shopCount',
      approvedShopTotal: 'shop/approvedShopTotal',
      userInfo: 'auth/infor',
      isClientChild: 'auth/isClientChild',
      shopUrl: 'event/shopUrl',
      hasManageShopsRole: 'auth/hasManageShopsRole',
      token: 'auth/token',
      isOldEvent: 'event/isOldEvent',
      parentEventDetail: 'event/parentEventDetail',
    }),
    dataTable() {
      return this.shopList.map(item => {
        const statusName = this.getShopStatusName(item.status);
        return {
          ...item,
          name: addThreeDotEndLine(item.name),
          company: addThreeDotEndLine(item.company),
          isCheckboxDisabled: ![StatusConstants.shop.waiting.label, StatusConstants.shop.hold.label].includes(statusName),
          status: {
            content: statusName,
            class: this.statusClass(statusName),
            value: item.status,
          },
          genreName: item.genre?.name || '',
          buttonLinks: [
            {
              content: '審査',
              class: 'btn btn-sm btn-green',
              function: () => this.showExamination(item),
              isNotShown: ![StatusConstants.shop.waiting.label, StatusConstants.shop.hold.label].includes(statusName)
            },
            {
              content: '管理',
              class: 'btn btn-sm btn-purple',
              function: () => this.handleRedirect(item),
              isNotShown: !this.hasManageShopsRole || this.isClientChild || (item.status !== StatusConstants.shop.approved.value),
            },
            { content: '編集', class: 'btn-bd-main', routeName: 'EventShopEdit', params: { id: item.id } },
          ].filter(obj => !obj.isNotShown),
          judgeDate: formatDateAndTime(item.judgeDate),
          registDate: formatDateAndTime(item.registDate),
        }
      })
    },
    subdomain() {
      return this.$route.params.subdomain;
    },
    importPath() {
      return `${this.userInfo?.id}_${this.subdomain}`;
    }
  },
  methods: {
    async handleRedirect(item) {
      if (item) {
        if (localStorage.getItem('refreshToken')) {
          const baseUrl = `${this.shopUrl}shop/event/dashboard?callback=${getEncodedRefreshToken()}&shopId=${item?.id}`;
          window.location.href = this.isOldEvent ? `${baseUrl}&isOldEvent=${this.isOldEvent}&subdomain=${this.parentEventDetail?.subdomain}` : baseUrl;
        } else {
          window.location.href = `${this.shopUrl}shop/event/dashboard`;
        }
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('token');
      }
    },
    getTotalByStatus() {
      const loading = this.$loading.show();
      Promise.all([
        this.$store.dispatch('shop/getShopTotalByStatus', StatusConstants.shop.approved.value),
        this.$store.dispatch('shop/getShopTotal', {
          subdomain: this.subdomain,
          limit: 1,
          offset: 1,
        }),
      ]).finally(() => {
        this.$loading.clear(loading);
      })
    },
    initStatusForm() {
      this.statusForm = {
        note: '',
        status: '',
        shops: [],
      };
    },
    showExamination(info) {
      this.statusForm = {
        ...this.statusForm,
        note: info.note || '',
        status: info.status || '',
        allFlag: false,
        shops: [info.id],
      }
      this.showModal();
    },
    handleCheckedData(checkedData) {
      this.statusForm = {
        ...this.statusForm,
        allFlag: checkedData.allFlag,
        shops: checkedData.ids,
        note: '',
        status: '',
      }
    },
    judgeShops() {
      if (!this.statusForm.allFlag && !this.statusForm.shops.length) {
        this.$message.showError('noSelectedShop');
      } else {
        this.showModal();
      }
    },
    handleClear() {
      this.$refs.table.resetCheck();
      this.closeModal();
    },
    async handleExamination() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.getErrors(this.$v.statusForm, 'judge');
      } else {
        const result = await this.$store.dispatch('shop/updateShopExamination', {
          ...this.statusForm,
          searchCondition: {
            ...this.searchConditions,
          },
        });
        this.$refs.table.resetCheck();
        this.closeModal();
        if (result) {
          if (result.count >= 1) {
            if (this.statusForm.status === StatusConstants.shop.hold.value) {
              this.$message.showSuccess('updatedWaitingStatus');
            } else {
              this.$message.showSuccess('updatedApprovedOrRejectedStatus');
            }
          } else {
            this.$message.showError('noUpdatedItem');
          }
          this.getTotalByStatus();
          this.$refs.pagination.getPaginationList();
          this.initStatusForm();
        }
      }
    },
    async hanldeClickDownload() {
      this.isDisable = true;
      const result = await this.$store.dispatch('shop/downloadShopList', {
        ...this.payloadDownloadCsv,
      });
      if (result) {
        this.saveIdRequestExport(result.id);
        this.checkExportCsvStatus(result.id);
      }
    },
    uploadCsv() {
      this.$refs.csvInput.click();
    },
    removeCsvFile() {
      this.$refs.csvInput.value = null;
    },
    checkImportStatus(id) {
      this.statusTimer = setTimeout(async () => {
        const result = await this.$store.dispatch('shop/getImportStatus', id);
        if (result) {
          switch (result.status) {
            case 0:
              this.checkImportStatus(id);
              break;
            case 1:
              this.$message.showSuccess('import_csv_finished_success');
              this.$refs.pagination.resetPagination();
              this.removeImportingSubdomain();
              break;
            case 2:
              if (result.count) this.$refs.pagination.resetPagination();
              this.downloadCsv(result.errorData, result.filename);
              this.$message.showSuccess('import_csv_finished_fail', true);
              this.removeImportingSubdomain();
              break;
            case 3:
              this.$message.showError('import_csv_fail');
              this.$refs.pagination.resetPagination();
              this.removeImportingSubdomain();
              break;
            case 4:
              this.$message.showError('import_csv_duplicate_fail');
              this.$refs.pagination.resetPagination();
              this.removeImportingSubdomain();
              break;
            default:
              break;
          }
        } else {
          this.removeImportingSubdomain();
        }
      }, 10000);
    },
    setImportingSubdomain(id) {
      this.isImportDisabled = true;
      const importingSubdomain = JSON.parse(localStorage.getItem('IMPORTING_SUBDOMAIN')) || {};
      importingSubdomain[this.importPath] = id;
      localStorage.setItem('IMPORTING_SUBDOMAIN', JSON.stringify(importingSubdomain));
    },
    removeImportingSubdomain() {
      this.isImportDisabled = false;
      const importingSubdomain = JSON.parse(localStorage.getItem('IMPORTING_SUBDOMAIN')) || {};
      delete importingSubdomain[this.importPath];
      localStorage.setItem('IMPORTING_SUBDOMAIN', JSON.stringify(importingSubdomain));
    },
    onCsvFilePicked(event) {
      if (!this.isImportDisabled) {
        const files = event.target.files || event.dataTransfer.files;
        const file = files[0];
        if (checkFileSize(file) && checkCsvFile(file)) {
          let reader = new FileReader();
          reader.onload = async (e) => {
            const csvResult = e.target.result;
            const detectedEncoding = Encoding.detect(csvResult);
            if (FileConstants.ACCEPTED_CSV_ENCODEING.includes(detectedEncoding)) {
              const formData = new FormData();
              formData.append('input', file);
              const result = await this.$store.dispatch('shop/importShopCsv', formData);
              if (result) {
                this.setImportingSubdomain(result.data.id);
                this.$message.showSuccess('importCsvStart');
                this.checkImportStatus(result.data.id);
              } else {
                this.removeImportingSubdomain();
              }
            } else {
              this.$message.showError('unacceptedCharsetCsv');
            }
          }
          reader.readAsBinaryString(file);
        }
      } else {
        this.$message.showError('importing_csv');
      }
      this.removeCsvFile();
    }
  },
  beforeDestroy() {
    window.removeEventListener('popstate', popStateListener);
    this.$store.commit('shop/SET_SHOP_LIST', []);
    clearTimeout(this.statusTimer);
  }
};
</script>
